import { Component, OnInit } from '@angular/core';
import { AuthGuard } from './guard/authentication.service';
import { ShareDataService } from './shared/services/shared.service';
import { TagManagerService } from './shared/services/tag-manager.service';
import { PendoService } from './shared/services/pendo.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit{
  title = 'assesment';
  loading: boolean;

  constructor(private auth: AuthGuard,
    private shareDataService: ShareDataService,
    private tagManagerService: TagManagerService,
    private pendoService: PendoService) {
     }

  cookieValue;

  ngOnInit() {
    // Set and get cookie values
	  this.auth.login();
    const pendoEnabled = environment.enablePendo;
    if(pendoEnabled){
      this.tagManagerService.addTag();
      this.pendoService.initializePendo();  // initialize Pendo
    }
  }
}
