import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TagManagerService {
  constructor() {}

  addTag() {
    const env = environment.tagManagerEnv;
    const auth = environment.tagManagerAuth;
    document.head.prepend(
      document.createRange().createContextualFragment(`
      <script>
        window.dataLayer = window.dataLayer || [];
      </script>
      <!-- Google Tag Manager -->
      <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${auth}&gtm_preview=${env}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-P6LJNS9X');</script>
      <!-- End Google Tag Manager -->
    `)
    );

    document.body.prepend(
      document.createRange().createContextualFragment(`
      <!-- Google Tag Manager (noscript) -->
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P6LJNS9X&gtm_auth=${auth}&gtm_preview=${env}&gtm_cookies_win=x"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      <!-- End Google Tag Manager (noscript) -->
    `)
    );
  }
}
