import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PendoConfig } from './interfaces/shared.interface';

declare const pendo: any; // declare the Pendo object

@Injectable({
  providedIn: 'root'
})
export class PendoService {

  private loadInterval: any = null;
  private intervalCounter = 0;
  private pendoInitialized = false;
  private orgXid = '';
  private userXid = '';
  private userRole = '';
  private connectLanguage = '';
  private userType = 'Instructor';
  private environment = '';
  private visitorParams = {};


  constructor() { }

  // Method to initialize Pendo based on user and org information
  initializePendo() {

    
      // read cookie value
      let params = decodeURIComponent(this.getCookie('CONNECT_PENDO'));
      if (params === '') {
        var cookieRead = decodeURIComponent(this.checkForCookie('_pendo___sg__'));
        if (cookieRead) {
          var userObj = JSON.parse(cookieRead);
          console.log(userObj);
          params = this.formatDataCookie(userObj);
        }
      }
      if (params !== '') {
        // break by '::'
        let paramsList = params.split('::');
        //when we have all params
        if (paramsList.length > 5) {
          this.orgXid = (paramsList[2]) ? paramsList[2] : 'NA';
          this.userXid = (paramsList[0]) ? paramsList[0] : 'NA';
          this.userRole = (paramsList[4]) ? paramsList[4] : 'NA';
          this.connectLanguage = (paramsList[5]) ? paramsList[5] : 'NA';
          this.userType = paramsList[6] || '';
          this.environment = (paramsList[1]) ? paramsList[1] : 'NA',
            this.visitorParams = {
              id: this.userXid,
              environment: (paramsList[1]) ? paramsList[1] : 'NA',
              org: this.orgXid,
              platform: "EZT TEST BUILDER",
              role: this.userRole,
              connectLanguage: this.connectLanguage,
              mh_courseDescriptor: this.userType
            };
        }
      }

      if (this.orgXid && this.userXid && !this.pendoInitialized) {
        this.loadInterval = setInterval(() => this.initPendo(), 1000);
      }
  }

  // This method will check if Pendo is available and initialize it
  private initPendo() {
    this.intervalCounter++;
    if (!window['pendo'] || !window['pendo'].validateInstall) {
      console.log('Pendo not available: Init Failed');
      if (this.intervalCounter === 30) {
        // Stop after trying for 30 seconds
        clearInterval(this.loadInterval);
      }
      return;
    }

    if (window['pendo'] && !this.pendoInitialized) {
      console.log('Initializing Pendo');

      // initializing pendo
      window['pendo'].initialize({
        visitor: {
          id: this.userXid,
          environment: this.environment,
          org: this.orgXid,
          platform: "EZT TEST BUILDER",
          role: this.userRole,
          connectLanguage: this.connectLanguage,
          mh_courseDescriptor: this.userType
        },
        account: {
          id: this.orgXid
        },
        disableGuides: false
      });

      if (window['pendo'].validateInstall && window['pendo'].validateEnvironment && !this.pendoInitialized) {
        console.log('Pendo successfully initialized');
        this.pendoInitialized = true;
        clearInterval(this.loadInterval);
      }
    }
  }

  // Method to set params
  setCookieParams() {
    // read cookie value
    let params = decodeURIComponent(this.getCookie('CONNECT_PENDO'));
    if (params == '') {
      var cookieRead = decodeURIComponent(this.checkForCookie('_pendo___sg__'));
      console.log('[' + cookieRead + ']');
      if (cookieRead) {
        var userObj = JSON.parse(cookieRead);
        console.log(userObj);
        params = this.formatDataCookie(userObj);
      }
    }
  }
  // Method to read cookies by name
  getCookie(cookieName: string): string {
    const name = cookieName + "=";
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookieData = cookies[i].trim();
      if (cookieData.indexOf(name) === 0) {
        return cookieData.substring(name.length, cookieData.length);
      }
    }
    return "";
  }

  // Method to check for specific keys in cookies
  checkForCookie(key: string): string {
    let value = '';
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookieData = cookies[i];
      if (cookieData.includes(key)) {
        value = cookieData.split('=')[1];
        break;
      }
    }
    return value;
  }

  // Method to format data for cookies
  formatDataCookie(userObj: any): string {
    return `${userObj.visitormetadata.agent__personxid || "NA"}::NA::${userObj.visitormetadata.agent__orgid || 'NA'}::EZT-TEST-BUILDER::student::${userObj.visitormetadata.agent__mh_orgcountry || 'NA'}::${userObj.visitormetadata.agent__mh_coursedescriptor || 'NA'}::`;
  }
}